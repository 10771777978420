import create from 'zustand';

import { login, sendOtpOrEmail, verifyPhone } from '@api/fetchers/login';

const useLoginStore = create((set) => ({
  initialAccountType: null,
  loggingIn: false,
  isSendingOtp: false,
  otpOrEmailData: null,
  loginResponse: null,
  verifyOtpResponse: {
    type: 'phone',
    submitting: false,
    success: false,
    error: false,
    errorMsg: ''
  },
  isNewLoginModal: false,
  IsLoginModalExperiment: false,
  isLoginModalVariation: null,
  login: async (body) => {
    set({ loggingIn: true });
    const loginResponse = await login(body);
    if (loginResponse?.error) {
      set({ loginResponse, loggingIn: false });
    } else {
      set({ loginResponse });
    }

    return loginResponse;
  },
  sendOtpOrEmail: async (body) => {
    set({ isSendingOtp: true });
    const otpOrEmailData = await sendOtpOrEmail(body);
    set({ otpOrEmailData, isSendingOtp: false });
    return otpOrEmailData;
  },
  resetOtpResponse: () => set({ otpOrEmailData: null }),
  verifyPhone: async (body) => {
    set({
      verifyOtpResponse: {
        type: 'phone',
        submitting: true,
        success: false,
        error: false,
        errorMsg: ''
      }
    });
    const verifyOtpResponse = await verifyPhone(body);
    set({
      verifyOtpResponse: {
        type: 'phone',
        submitting: false,
        success: !verifyOtpResponse?.error,
        error: !!verifyOtpResponse?.error,
        errorMsg: verifyOtpResponse?.error?.message || ''
      }
    });
    return verifyOtpResponse;
  },
  resetVerifyOtpResponse: () => set({ verifyOtpResponse: null }),

  setLocalPhoneNumber: (localPhoneNumber, localSelectedCountry) =>
    set({ localPhoneNumber, localSelectedCountry }),

  setLocalEmailAndName: (localEmail, localName) =>
    set({ localEmail, localName }),

  setInitialAccountType: (initialAccountType) => set({ initialAccountType }),
  setIsNewLoginModal: (isNewLoginModal) => set({ isNewLoginModal }),
  setIsLoggingIn: (loggingIn) => set({ loggingIn }),
  setIsLoginModalExperiment: (IsLoginModalExperiment) =>
    set({ IsLoginModalExperiment }),
  setIsLoginModalVariation: (isLoginModalVariation) =>
    set({ isLoginModalVariation })
}));

export default useLoginStore;
