/* eslint-disable import/prefer-default-export */
import config from '@api/fetchers/config';
import fetch from '@api/fetchers';

export const login = ({
  email,
  phone,
  otp,
  countryCode,
  backend,
  firstName,
  lastName,
  state,
  password,
  register,
  forClassroom = false,
  code = '',
  ageGroupID = '',
  accessToken
}) =>
  fetch('v1/user/login/?enable-email=true', {
    requestType: 'POST',
    useAuthToken: false,
    useCamelCase: false,
    body: {
      backend,
      phone,
      otp,
      access_token: accessToken,
      client_id: config.apiClientId,
      client_secret: config.apiClientSecret,
      country_code: countryCode,
      email,
      first_name: firstName,
      last_name: lastName,
      state,
      password,
      register,
      user_info: false,
      is_un_teach_user: forClassroom,
      un_teach_institue_code: code,
      country_age_group_uid: ageGroupID
    }
  });

export const sendOtpOrEmail = ({
  phone = '',
  countryCode,
  otpType,
  email = '',
  forClassroom = false
}) =>
  fetch('v3/user/user_check/?enable-email=true', {
    requestType: 'POST',
    useAuthToken: false,
    useCamelCase: false,
    body: {
      phone,
      country_code: countryCode,
      otp_type: otpType,
      email,
      send_otp: true,
      is_un_teach_user: forClassroom
    }
  });

export const verifyPhone = ({ phone, countryCode, otpType }) =>
  fetch('v1/user/me/verify_phone_otp/', {
    requestType: 'POST',
    useCamelCase: false,
    body: {
      phone,
      country_code: countryCode,
      otp_type: otpType
    }
  });
