/* eslint-disable react-hooks/exhaustive-deps */
import useFetch from '@hooks/useFetch';

const useGoalInfo = (
  goalUID,
  options = { selector: null, fallbackData: undefined }
) => {
  const key = `v1/topology/users/${goalUID}/self/`;

  const {
    data: goalInfo,
    isValidating,
    ...rest
  } = useFetch(
    goalUID ? key : null,
    {},
    { fallbackData: options.fallbackData }
  );

  if (options.selector && typeof options.selector === 'function')
    return options.selector(goalInfo || {});
  return {
    key,
    goalInfo,
    isValidating,
    ...rest
  };
};

export default useGoalInfo;
