import styled from '@emotion/styled';
import MUIDrawer from '@material-ui/core/Drawer';
import PropTypes from 'prop-types';
import ArrowBack from '@icon/ArrowBack';
import Close from '@icon/Close';
import { getImage } from '@utils/imageHelper';
import { Spacings, mediaQuery } from '@styles/index';

const fontGrey = 'var(--color-text-primary)';

const StyledDrawer = styled(MUIDrawer)`
  &.MuiDrawer-root > .MuiPaper-root {
    background-color: ${({ $backgroundColor }) =>
      $backgroundColor || 'var(--color-base-0)'};
    display: grid;
    grid-template-columns: repeat(10, ${Spacings.SPACING_8B});
    grid-column-gap: ${Spacings.SPACING_4B};
    grid-auto-rows: max-content;
    padding-left: ${Spacings.SPACING_12B};
    padding-top: ${Spacings.SPACING_6B};
    padding-right: 115px;
    overflow-x: hidden;
    background-image: ${({ $imageURL }) =>
      $imageURL && `url(${getImage($imageURL)})`};
    background-position: ${({ $imagePosition }) => $imagePosition};
    background-repeat: no-repeat;
    background-size: ${({ $imageSize }) => $imageSize};
    background-attachment: ${({ $backgroundAttachment }) =>
      $backgroundAttachment};
    ${mediaQuery.uptoMobile} {
      width: 100%;
      padding-left: ${Spacings.SPACING_4B};
      padding-right: ${Spacings.SPACING_4B};
      overflow-x: initial;
      grid-template-columns: repeat(10, 1fr);
    }
    ${({ $login, $hideCloseButton }) => {
      return (
        !$hideCloseButton && `grid-template-rows: 60px ${$login ? '1fr' : ''};`
      );
    }}
  }
`;

const CloseWrapper = styled.div`
  grid-column: 1/11;
  cursor: pointer;
  @media (max-width: 47.95em) {
    grid-column: 1/2;
    width: 24px;
    height: 24px;
  }
`;

const Drawer = ({
  children,
  anchor,
  backgroundColor,
  drawerState,
  onClose,
  hideCloseButton,
  showBackButton,
  elevation,
  onPressBack,
  imageURL,
  imagePosition,
  imageSize,
  backgroundAttachment,
  disableBackdropClick,
  disableEscapeKeyDown,
  login,
  variant,
  className
}) => {
  return (
    <StyledDrawer
      anchor={anchor}
      $backgroundColor={backgroundColor}
      open={drawerState}
      onClose={onClose}
      PaperProps={{ id: 'DrawerPaper' }}
      $imageURL={imageURL}
      $imagePosition={imagePosition}
      $imageSize={imageSize}
      $backgroundAttachment={backgroundAttachment}
      disableEscapeKeyDown={disableEscapeKeyDown}
      disableBackdropClick={disableBackdropClick}
      $login={login}
      $hideCloseButton={hideCloseButton}
      variant={variant}
      className={className}
      elevation={elevation}
    >
      {!hideCloseButton && (
        <CloseWrapper>
          <Close size="24px" onClick={onClose} color={fontGrey} />
        </CloseWrapper>
      )}
      {showBackButton && (
        <CloseWrapper>
          <ArrowBack size="24px" onClick={onPressBack} color={fontGrey} />
        </CloseWrapper>
      )}
      {children}
    </StyledDrawer>
  );
};

export default Drawer;

Drawer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.node]),
  backgroundColor: PropTypes.string,
  anchor: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
  drawerState: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  hideCloseButton: PropTypes.bool,
  showBackButton: PropTypes.bool,
  onPressBack: PropTypes.func,
  imageURL: PropTypes.string,
  imagePosition: PropTypes.string,
  imageSize: PropTypes.string,
  backgroundAttachment: PropTypes.string,
  disableEscapeKeyDown: PropTypes.bool,
  disableBackdropClick: PropTypes.bool,
  login: PropTypes.bool,
  variant: PropTypes.string,
  className: PropTypes.string,
  elevation: PropTypes.number
};

Drawer.defaultProps = {
  children: null,
  backgroundColor: null,
  anchor: 'right',
  drawerState: false,
  hideCloseButton: false,
  showBackButton: false,
  onPressBack: null,
  imageURL: null,
  imagePosition: null,
  imageSize: null,
  backgroundAttachment: null,
  elevation: 16,
  disableEscapeKeyDown: false,
  disableBackdropClick: false,
  login: false,
  variant: 'temporary',
  className: ''
};
