import useMyInfo from '@api/hooks/auth/useMyInfo';
import { readCookie } from '@utils/cookies';
import RoundedRectSkeleton from '@skeletons/RoundedRectSkeleton';
import { mediaQuery, Spacings } from '@styles/index';
import styled from '@emotion/styled';
import Actions from './Actions';

const LoginSkeletonRapper = styled.div`
  display: flex;
  gap: ${Spacings.SPACING_4B};
  ${mediaQuery.uptoMobile} {
    width: 100%;
    justify-content: end;
    gap: ${Spacings.SPACING_2B};
  }
`;
const RenderLoginAction = ({ show = true, isJoinForFreeCtaExperiment }) => {
  const { isError: failedToFetchUserData, isValidating: fetchingUserData } =
    useMyInfo();
  const hideLoginButton = !show;

  if (hideLoginButton) return <></>;
  if (
    !failedToFetchUserData &&
    (fetchingUserData || readCookie('accessToken'))
  ) {
    return (
      <LoginSkeletonRapper>
        <RoundedRectSkeleton height={25} width={25} />
      </LoginSkeletonRapper>
    );
  }

  return <Actions isJoinForFreeCtaExperiment={isJoinForFreeCtaExperiment} />;
};

export default RenderLoginAction;
