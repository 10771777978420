import styled from '@emotion/styled';

const SkeletonGradient = styled.div`
  background: linear-gradient(
    90deg,
    var(--color-divider) -4.32%,
    var(--color-base-0) 100%
  );
`;

export default SkeletonGradient;
