import Icon from './Icon';

const ArrowBack = (props) => (
  <Icon
    {...props}
    path="M66.9 37.5h-47.267l15.7-15.833c0.452-0.452 0.731-1.077 0.731-1.767s-0.279-1.314-0.731-1.767v0c-0.452-0.452-1.077-0.731-1.767-0.731s-1.314 0.279-1.767 0.731v0l-20 20.2c-0.433 0.424-0.702 1.015-0.702 1.668 0 0.035 0.001 0.069 0.002 0.103l-0-0.005c0.001 0.69 0.281 1.315 0.733 1.767v0l20.2 20c0.442 0.453 1.059 0.733 1.741 0.733 0.009 0 0.018-0 0.027-0h-0.001c1.365-0.020 2.465-1.131 2.465-2.5 0-0.69-0.279-1.315-0.731-1.767v0l-16-15.833h47.367c1.381 0 2.5-1.119 2.5-2.5s-1.119-2.5-2.5-2.5v0z"
  />
);

export default ArrowBack;
