import styled from '@emotion/styled';
import { mediaQuery } from '@styles/index';

const HeaderParent = styled.div`
  width: 1136px;
  margin: auto;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  ${mediaQuery.uptoTablet} {
    width: 728px;
  }
`;

export default HeaderParent;
