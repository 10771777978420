import styled from '@emotion/styled';

const HeaderContainer = styled.header`
  height: 72px;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 3;
`;

export default HeaderContainer;
