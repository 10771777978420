export const getLoginResponse = (auth) => auth.loginResponse;

export const getIsLoggingIn = (auth) => auth.loggingIn;
export const getIsSendingOtp = (auth) => auth.isSendingOtp;

export const getLoginMethod = (auth) =>
  auth.otpOrEmailData?.login_method ?? false;

export const getOtpOrEmailResponse = (auth) => auth.otpOrEmailData;

export const getIsRegistered = (auth) =>
  auth.otpOrEmailData?.is_registered ?? false;

export const getIsPartialUser = (auth) => {
  const { is_email_verified, is_registered, has_name } =
    auth?.otpOrEmailData || {};
  if (is_registered && (is_email_verified || has_name)) return false;
  return auth.otpOrEmailData?.is_partial_user ?? false;
};

export const getIsPhoneVerified = (auth) =>
  auth.otpOrEmailData?.verify_phone ?? false;

export const getLocalPhoneNumber = (auth) => auth.localPhoneNumber;
export const getLocalSelectedCountry = (auth) => auth.localSelectedCountry;

export const getLocalEmail = (auth) => auth.localEmail;
export const getLocalName = (auth) => auth.localName;
export const getInitialAccountType = (auth) => auth.initialAccountType;
export const getIsNewLoginModal = (auth) => auth.isNewLoginModal;
export const getIsLoginModalExperiment = (auth) => auth.IsLoginModalExperiment;
export const getIsLoginModalVariation = (auth) => auth.isLoginModalVariation;

// Setters and actions
export const getLoginAction = (auth) => auth.login;
export const getOTPSender = (auth) => auth.sendOtpOrEmail;
export const getOTPDataCleaner = (auth) => auth.resetOtpResponse;
export const getPhoneVerifier = (auth) => auth.verifyPhone;
export const getResetVerifyOtpResponse = (auth) => auth.resetVerifyOtpResponse;
export const getSetLocalPhoneNumber = (auth) => auth.setLocalPhoneNumber;
export const getSetLocalEmailAndName = (auth) => auth.setLocalEmailAndName;
export const getSetInitialAccountType = (auth) => auth.setInitialAccountType;
export const getSetIsNewLoginModal = (auth) => auth.setIsNewLoginModal;
export const getSetIsLoggingIn = (auth) => auth.setIsLoggingIn;
export const getSetIsLoginModalExperiment = (auth) =>
  auth.setIsLoginModalExperiment;
export const getSetIsLoginModalVariation = (auth) =>
  auth.setIsLoginModalVariation;
