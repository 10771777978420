import styled from '@emotion/styled';
import Button from '@base/Button/Button';
import { segment } from '@actions/analytics';
import { Fonts, mediaQuery, Spacings } from '@styles/index';
import constants, { LOGIN_STARTED } from '@cont/Login/constants';
import { useToggleable } from '@stores/toggleables';
import { LOGIN_DRAWER } from '@constants/toggleables';
import { useRouter } from 'next/router';
import useGoalInfo from '@api/hooks/goal/useGoalInfo';
import AquillaButton from 'aquilla/core/molecules/Button';
import { getSetInitialAccountType } from '@stores/selectors/login';
import useLoginStore from '@stores/login/loginStore';
import segmentStorage from '@events/segmentStorage';

const StyledDiv = styled(Button)`
  grid-column: 23/25;
  align-self: center;
  display: flex;
  ${mediaQuery.uptoTablet} {
    grid-column: 22/25;
    min-height: 32px;
    height: 32px;
  }
  ${mediaQuery.uptoMobile} {
    color: var(--color-i-green);
    background-color: transparent;
    height: 14px;
    width: auto;
    grid-column: 7/9;
    &:active {
      background-color: transparent;
      border: none;
      transform: none;
    }
  }
`;
const LoginCTAWrapper = styled.div`
  display: flex;
  gap: ${Spacings.SPACING_4B};
  ${mediaQuery.uptoMobile} {
    width: 100%;
    justify-content: end;
    gap: ${Spacings.SPACING_2B};
  }
`;

const NewLoginCTA = styled(AquillaButton)`
  padding: ${Spacings.SPACING_3B} ${Spacings.SPACING_5B};
  width: 104px;
  height: 40px;
  border-radius: ${Spacings.SPACING_6};
  font-weight: 600;
  font-size: ${Fonts.SMALL};
  line-height: ${Spacings.SPACING_4B};
  white-space: nowrap;
  ${mediaQuery.uptoMobile} {
    padding: ${Spacings.SPACING_2B} ${Spacings.SPACING_14};
    height: 32px;
    width: unset;
    min-width: 64px;
    max-width: 96px;
  }
`;
const NewStyleButton = styled(AquillaButton)`
  grid-column: 23/25;
  align-self: center;
  display: flex;
  ${mediaQuery.uptoTablet} {
    grid-column: 22/25;
    padding: ${Spacings.SPACING_2B} ${Spacings.SPACING_5B};
    min-width: unset;

    .button--label-content {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
    }
  }
`;

const acpfNudgeUrls = [
  '/goal/[goalSlug]/[goalUID]',
  '/goal/[goalSlug]/[goalUID]/home',
  '/goal/[goalSlug]/[goalUID]/subscriptions',
  '/goal/[goalSlug]/[goalUID]/success-story',
  '/goal/[goalSlug]/[goalUID]/store'
];

const Actions = ({ isJoinForFreeCtaExperiment }) => {
  const router = useRouter();
  const isContentPage = router.asPath?.startsWith('/content/');
  const { goalInfo } = useGoalInfo(
    isContentPage ? router.query?.goalUID : null
  );
  const { route } = router;

  const setInitialAccountType = useLoginStore(getSetInitialAccountType);
  const { openElement: openLoginDrawer } = useToggleable(LOGIN_DRAWER);
  const login = (e) => {
    segment.track(LOGIN_STARTED, { cta_button: e?.target?.textContent ?? '' });
    /**
     * If the login is clicked from any Content pages that is housed in Unacademy WEB,
     * we trigger this event.
     */
    if (isContentPage && goalInfo) {
      segment.track('Content page - CTA clicked', {
        button_text: 'Login',
        source_type: 'content_pages',
        goal_name: goalInfo?.name,
        goal_uid: goalInfo?.uid
      });
    }
    openLoginDrawer();
  };

  const handleLogin = (e) => {
    segmentStorage.setItem('lpss', 'Header');
    setInitialAccountType(constants.ACCOUNT_TYPE_LOGIN);
    login(e);
  };

  const handleSignup = (e) => {
    setInitialAccountType(constants.ACCOUNT_TYPE_SIGNUP);
    login(e);
  };

  if (isJoinForFreeCtaExperiment) {
    return (
      <LoginCTAWrapper>
        <NewLoginCTA onClick={handleLogin} variant="secondary">
          Log in
        </NewLoginCTA>
        <NewLoginCTA onClick={handleSignup}>Join for free</NewLoginCTA>
      </LoginCTAWrapper>
    );
  }

  return (
    <>
      {acpfNudgeUrls.includes(route) ? (
        <NewStyleButton onClick={handleLogin}>Log in</NewStyleButton>
      ) : (
        <StyledDiv
          label="Log in"
          variant="filled"
          onClick={handleLogin}
          grid={2}
        />
      )}
    </>
  );
};

export default Actions;
