import styled from '@emotion/styled';
import SkeletonGradient from './SkeletonGradient';

const RoundedRectSkeleton = styled(SkeletonGradient)`
  border-radius: ${({ height }) => height / 2}px;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;

export default RoundedRectSkeleton;
